import './Door.css'
import React from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'
import { BiLockAlt } from 'react-icons/bi'

import img1 from '../../assets/images/backfaces/glenfiddich_01.gif'
import img2 from '../../assets/images/backfaces/glenfiddich_02.gif'
import img3 from '../../assets/images/backfaces/glenfiddich_03.gif'
import img4 from '../../assets/images/backfaces/glenfiddich_04.gif'
import img5 from '../../assets/images/backfaces/glenfiddich_05.gif'
import img6 from '../../assets/images/backfaces/glenfiddich_06.gif'

const imgs = [img1, img2, img3, img4, img5, img6]

interface DoorProps {
  i: number
  j: number
  index: number
  state: CardState
  margin: number
  onClick: () => void
  zIndex: number
}

function Door({ i, j, index, state, margin, onClick, zIndex }: DoorProps) {
  const variants = {
    init: {
      opacity: 0,
      transition: {
        duration: 0.4,
        delay: 0.4,
      },
    },
    active: {
      transform: 'rotateY(-90deg)',
      opacity: 0,
    },

    revealed: {
      transform: 'rotateY(-90deg)',
      opacity: 0,
    },
  }

  return (
    <motion.div
      className={classNames('door', `door-${index + 1}`)}
      variants={variants}
      animate={state}
      transition={{
        type: 'spring',
        stiffness: 80,
        duration: 0.4,
        opacity: {
          duration: 0.8,
        },
      }}
      style={{
        zIndex: zIndex,
        translate: `${i * (100 + margin)}% ${j * (100 + margin)}% 0em`,
      }}
      onClick={onClick}
    >
      <img className='door--img' src={imgs[index]} alt='' />
      <div className='door--numbering'>
        {state === 'locked' ? <BiLockAlt className='lock' /> : index + 1}
      </div>
    </motion.div>
  )
}
export default Door
