import React, { Fragment } from 'react'
import classNames from 'classnames'
import { useLocalStorage } from 'usehooks-ts'
import { AnimatePresence, motion } from 'framer-motion'
import useOutsideClick from '../hooks/useOutsideClick'
import './Grid.css'
import Card from '../Card'
import Door from '../Door'

import glenfiddich from '../../assets/images/glenfiddich.png'
import giftCard2 from '../../assets/images/gift-card-2.png'
import giftBox from '../../assets/images/gift-box.png'
import donno from '../../assets/images/donno.png'

const COL_NUM = 3
const ROW_NUM = 2
const MARGIN = 5 //(%)

const gifts = [glenfiddich, donno, giftBox, giftCard2, giftBox, giftCard2]

const zIndexesInit = [1, 1, 1, 1, 1, 1]
const cardsInitState: CardState[] = new Array(6)
  .fill(null)
  // .map((_, i) => (i + 10 < new Date().getUTCDate() ? 'hidden' : 'locked'))
  .map((_, i) => 'hidden')

function Grid() {
  const [cards, setCards] = useLocalStorage('cards', cardsInitState)
  const [zIndexes, setZIndexes] = useLocalStorage('zIndexes', zIndexesInit)

  const ref = useOutsideClick(() =>
    setCards((cards) =>
      cards.map((card) => (card === 'active' ? 'revealed' : card))
    )
  )

  const elements: JSX.Element[] = []
  for (let i = 0; i < COL_NUM; i++) {
    for (let j = 0; j < ROW_NUM; j++) {
      const index = j * COL_NUM + i
      const state = cards[index]
      elements.push(
        <Fragment key={index}>
          <Card
            {...{ i, j, index, state }}
            margin={MARGIN}
            onOutsideClick={() =>
              setCards((cards) =>
                cards.map((card) => (card === 'active' ? 'revealed' : card))
              )
            }
            gift={gifts[index]}
            zIndex={zIndexes[index]}
          />

          <Door
            {...{ i, j, index, state }}
            margin={MARGIN}
            onClick={() =>
              setCards((cards) =>
                cards.map((card, index2) => {
                  if (index !== index2) {
                    return card
                  }
                  if (cards[index] === 'hidden') {
                    setZIndexes((z) =>
                      z.map((zIndex, index3) => {
                        if (index2 !== index3) {
                          return zIndex
                        }
                        return Math.max(...zIndexes) + 1
                      })
                    )
                    return 'active'
                  }
                  return card
                })
              )
            }
            zIndex={zIndexes[index]}
          />
        </Fragment>
      )
    }
  }
  return (
    <motion.div
      className={classNames('grid', {
        'grid--with-active': cards.includes('active'),
      })}
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        delay: 0.5,
        duration: 1,
      }}
    >
      <AnimatePresence initial={false}>{elements}</AnimatePresence>
    </motion.div>
  )
}
export default Grid
