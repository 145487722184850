import React, { useState } from 'react'
import Grid from './components/Grid'

function App() {
  const [key, setKey] = useState(0)
  return (
    <div className='App'>
      <Grid key={key} />
      <button
        className='reset-button'
        onClick={() => {
          localStorage.clear()
          setKey((k) => k + 1)
        }}
      >
        újraindít
      </button>
    </div>
  )
}

export default App
