import './Card.css'
import React, { useEffect } from 'react'
import classNames from 'classnames'
import useOutsideClick from '../hooks/useOutsideClick'
import { motion } from 'framer-motion'

interface CardProps {
  i: number
  j: number
  index: number
  state: CardState
  margin: number
  onOutsideClick: () => void
  gift: string
  zIndex: number
}

function Card({
  i,
  j,
  index,
  state,
  margin,
  onOutsideClick,
  gift,
  zIndex,
}: CardProps) {
  const isLocked = state === 'locked'
  const isHidden = state === 'hidden'
  const isActive = state === 'active'
  const isRevealed = state === 'revealed'

  const ref = useOutsideClick(onOutsideClick)

  const variants = {
    active: {
      translate: `${100 + margin}% ${(100 + margin) / 2}% 17em`,
      transition: {
        type: 'spring',
        duration: 0.4,
        stiffness: 80,
        delay: 0.2,
      },
    },
    revealed: {
      transition: {
        delay: 0,
      },
    },
  }

  return (
    <motion.div
      variants={variants}
      animate={state}
      className={classNames('card', {
        'card--locked': isLocked,
        'card--hidden': isHidden,
        'card--active': isActive,
        'card--revealed': isRevealed,
      })}
      style={{
        zIndex: zIndex,
        translate: `${i * (100 + margin)}% ${j * (100 + margin)}% 0em`,
      }}
      ref={ref}
    >
      <img className='card--img' src={gift} alt='' />
    </motion.div>
  )
}
export default Card
